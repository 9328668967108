nav {
  padding: 5px 0px;
}

.marginLeft{
  margin-left: 1px;
}

 .uiList{
    list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.liList {
    margin-right: 20px;
    position: relative;
}

.ankerLink {
  
    text-decoration: none;
    color:gray;
    font-size: 13px;
    font-weight: 600;
    /* transition: color 0.3s ease; */
}
.liList::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #471362; /* Change to your desired hover color */
    bottom: 0;
    left: 0;
    transition: width 0.3s ease; /* Animation duration and easing */
  }

.ankerLink:hover {
    color: #471362;
}
.liList:hover::before,
.liList.selected::before  {
    bottom: -6px;
    color: #471362 !important;
    width: 100%;
    animation: drawLine 0.5s ease; /* Animation duration and easing */
  }



  @keyframes drawLine {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .FixedSaverHeader{
    background-color: #fff; /* White background color */
    color: #333; /* Text color */
    padding: 15px; /* Padding around the content inside the header */
    text-align: center; /* Center-align text */
  }
