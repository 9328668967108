// rotate
.rotate {
  display: inline-block;
  vertical-align: middle;

  .cd-text {
    position: absolute !important;
  }
}

.cd-words-wrapper {
  max-width: 100%;
  display: inline-block;
  position: relative;
  text-align: left;
  will-change: transform;
  line-height: 1.5;

  .cd-text {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 5px;
    top: 0;
    right: 0;
    background: var(--text-color);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    padding-right: 10px;

    &:after {
      background-color: var(--color);
    }

    &.is-visible {
      position: relative;
    }
  }
}

.no-js .cd-words-wrapper .cd-text {
  opacity: 0;
}

.no-js .cd-words-wrapper .cd-text.is-visible {
  opacity: 1;
}

.cd-headline,
.rotate,
.cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline,
.rotate .cd-text {
  opacity: 0;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
}

.cd-headline,
.rotate .cd-text.is-visible {
  opacity: 1;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  -ms-transform: rotateX(0);
  -o-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-animation: cd-rotate-in 1.2s;
  -moz-animation: cd-rotate-in 1.2s;
  animation: cd-rotate-in 1.2s;
}

.cd-headline,
.rotate .cd-text.is-hidden {
  -webkit-animation: cd-rotate-out 1.2s;
  -moz-animation: cd-rotate-out 1.2s;
  animation: cd-rotate-out 1.2s;
}

@-webkit-keyframes cd-rotate-in {
  0% {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -webkit-transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(360deg);
    opacity: 1;
  }
}

@-moz-keyframes cd-rotate-in {
  0% {
    -moz-transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -moz-transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -moz-transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes cd-rotate-in {
  0% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }

  35% {
    -webkit-transform: rotateX(120deg);
    -moz-transform: rotateX(120deg);
    -ms-transform: rotateX(120deg);
    -o-transform: rotateX(120deg);
    transform: rotateX(120deg);
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(360deg);
    -moz-transform: rotateX(360deg);
    -ms-transform: rotateX(360deg);
    -o-transform: rotateX(360deg);
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@-webkit-keyframes cd-rotate-out {
  0% {
    -webkit-transform: rotateX(0);
    opacity: 1;
  }

  35% {
    -webkit-transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    opacity: 0;
  }
}

@-moz-keyframes cd-rotate-out {
  0% {
    -moz-transform: rotateX(0);
    opacity: 1;
  }

  35% {
    -moz-transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -moz-transform: rotateX(180deg);
    opacity: 0;
  }
}

@keyframes cd-rotate-out {
  0% {
    -webkit-transform: rotateX(0);
    -moz-transform: rotateX(0);
    -ms-transform: rotateX(0);
    -o-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }

  35% {
    -webkit-transform: rotateX(-40deg);
    -moz-transform: rotateX(-40deg);
    -ms-transform: rotateX(-40deg);
    -o-transform: rotateX(-40deg);
    transform: rotateX(-40deg);
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    transform: rotateX(180deg);
    opacity: 0;
  }
}

.loading-bar {
  overflow: hidden;
  vertical-align: top;
}

.loading-bar {
  .cd-text::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background-color: var(--line-color, #4132e0) !important;
    z-index: 2;
    -webkit-transition: width 0.3s -0.1s;
    -moz-transition: width 0.3s -0.1s;
    transition: width 0.3s -0.1s;
  }
}

.loading-bar .is-visible::after {
  width: 100%;
  -webkit-transition: width 3s;
  -moz-transition: width 3s;
  transition: width 3s;
}

.loading-bar .cd-text {
  top: 0.2em;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.loading-bar .cd-text.is-visible {
  opacity: 1;
  top: 0;
}

// Slide
.cd-words-wrapper {
  &.fancy-border-bottom {
    .cd-text {
      &::after {
        content: "";
        width: 100%;
        bottom: 0;
        left: 15px;
        height: 2px;
        position: absolute;
        display: inline-block;
        background: var(--text-color);
      }
    }
  }
}

.cd-headline,
.slide span {
  display: inline;
}

.cd-headline,
.slide .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline,
.slide .cd-text {
  opacity: 0;
  top: 0.2em;
}

.cd-headline,
.slide .cd-text.is-visible {
  top: 0;
  opacity: 1;
  -webkit-animation: slide-in 0.6s;
  -moz-animation: slide-in 0.6s;
  animation: slide-in 0.6s;
}

.cd-headline,
.slide .cd-text.is-hidden {
  -webkit-animation: slide-out 0.6s;
  -moz-animation: slide-out 0.6s;
  animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(20%);
    -moz-transform: translateY(20%);
    -ms-transform: translateY(20%);
    -o-transform: translateY(20%);
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}

@-moz-keyframes slide-out {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  60% {
    opacity: 0;
    -moz-transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateY(120%);
    -moz-transform: translateY(120%);
    -ms-transform: translateY(120%);
    -o-transform: translateY(120%);
    transform: translateY(120%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}

// Clip

.cd-headline.clip span {
  display: inline-block;
  padding: 0.2em 0;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #aebcb9;
}

.cd-headline.clip .cd-text {
  opacity: 0;
}

.cd-headline.clip .cd-text.is-visible {
  opacity: 1;
}

.cd-headline,
.zoom .cd-words-wrapper {
  -webkit-perspective: 300px;
  -moz-perspective: 300px;
  perspective: 300px;
}

.cd-headline,
.zoom .cd-text {
  opacity: 0;
}

.cd-headline,
.zoom .cd-text.is-visible {
  opacity: 1;
  -webkit-animation: zoom-in 0.8s;
  -moz-animation: zoom-in 0.8s;
  animation: zoom-in 0.8s;
}

.cd-headline,
.zoom .cd-text.is-hidden {
  -webkit-animation: zoom-out 0.8s;
  -moz-animation: zoom-out 0.8s;
  animation: zoom-out 0.8s;
}

@-webkit-keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
  }
}

@-moz-keyframes zoom-in {
  0% {
    opacity: 0;
    -moz-transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateZ(0);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(100px);
    -moz-transform: translateZ(100px);
    -ms-transform: translateZ(100px);
    -o-transform: translateZ(100px);
    transform: translateZ(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-100px);
  }
}

@-moz-keyframes zoom-out {
  0% {
    opacity: 1;
    -moz-transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateZ(-100px);
  }
}

@keyframes zoom-out {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateZ(-100px);
    -moz-transform: translateZ(-100px);
    -ms-transform: translateZ(-100px);
    -o-transform: translateZ(-100px);
    transform: translateZ(-100px);
  }
}

// Push

.cd-headline,
.push .cd-text {
  opacity: 0;
}

.cd-headline,
.push .cd-text.is-visible {
  opacity: 1;
  -webkit-animation: push-in 0.6s;
  -moz-animation: push-in 0.6s;
  animation: push-in 0.6s;
}

.cd-headline,
.push .cd-text.is-hidden {
  -webkit-animation: push-out 0.6s;
  -moz-animation: push-out 0.6s;
  animation: push-out 0.6s;
}

@-webkit-keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes push-in {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
    -moz-transform: translateX(10%);
    -ms-transform: translateX(10%);
    -o-transform: translateX(10%);
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
}

@-moz-keyframes push-out {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  60% {
    opacity: 0;
    -moz-transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
    -moz-transform: translateX(110%);
    -ms-transform: translateX(110%);
    -o-transform: translateX(110%);
    transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.type {
  .cd-text {
    visibility: hidden;

    &.is-visible {
      visibility: visible;
    }
  }

  i {
    position: absolute;
    visibility: hidden;
    font-style: normal;

    &.in {
      position: relative;
      visibility: visible;
    }
  }
}

.flip {
  .cd-text {
    opacity: 0;

    &.is-visible {
      opacity: 1;
    }
  }

  i {
    display: inline-block;
    background: var(--text-color);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    opacity: 0;
    font-style: normal;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transition: transform 0.3s ease-in-out;

    &.in {
      opacity: 1;
      transform: translateZ(-20px) rotateX(0deg);
    }

    &.out {
      opacity: 0;
      transform: translateZ(-20px) rotateX(-90deg);
    }
  }
}

/* Animated text */
.animated-text {
  .cd-text {
    background: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right top, #0039e3, #4132e0, #5e28dd, #741bd9, #8600d4);
    }
  }
}

.animated-text-black {
  .cd-text {
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 3px solid;
    font-weight: 600;

    &:after {
      display: none;
    }
  }
}